* {
  padding: 0;
  margin: 0;
}

html,
body {
  // width: calc(100vw - 1rem);
  // overflow-y: hidden;
  // overflow-x:hidden;
 }

#root {
  height: 100%;
  width: 100%;
}